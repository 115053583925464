import { FC } from "react";
import "./modal-styles.css";

type Props = {
  isHidden: boolean;
  onClose(): void;
}

export const ContactModal: FC<Props> = ({ isHidden, onClose }) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <ul>
          <li>Email: <a href="mailto:rod@praxisranch.biz">rod@praxisranch.biz</a></li>
          <li>Phone: <a href="tel:4062082798">(406) 208-2798</a></li>
        </ul>
       
      </div>
    </div>
  )
}