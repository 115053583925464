import React, { useState } from 'react';
import consulting from './consulting.jpg';
import coaching from './coaching.jpg';
import mentoring from './mentoring.jpg';
import planning from './planning.jpg';
import logo from './logo-white.png';
import aboutMe from './about-me.jpg';
import './styles.css';
import { ContactModal } from './ContactModal';

function App() {
  const [isModalHidden, setIsModalHidden] = useState(true);

  const handleOnContactClick = () => {
    setIsModalHidden(false);
  }

  return (
    <>
      <ContactModal isHidden={isModalHidden} onClose={() => setIsModalHidden(true)} />
      <div className="App">
        <div className="cover">
          <div className="logo-container">
            <img className="logo" src={logo} alt="Praxis Ranch Consulting" />
            <button className="contact-button" onClick={handleOnContactClick}>Contact</button>
            <p className="motto">Building a better life with practical solutions for your ranch, your family, and yourself</p>
          </div>
        </div>

        <div className="about">
          <div className="grid-container">
            <div className="grid section-1">
              <div style={{ backgroundImage: `url(${consulting})` }} className="grid-image" />
              <h2 className="services-text">Consulting</h2>
            </div>
            <div className="grid section-1">
              <div style={{ backgroundImage: `url(${coaching})` }} className="grid-image coaching-image" />
              <h2 className="services-text">Coaching</h2>
            </div>
            <div className="grid section-2 row-1">
              <div style={{ backgroundImage: `url(${planning})` }} className="grid-image" />
              <h2 className="services-text">Mentoring</h2>
            </div>
            <div className="grid section-2 row-2">
              <div style={{ backgroundImage: `url(${mentoring})` }} className="grid-image" />
              <h2 className="services-text">Succession Planning</h2>
            </div>
          </div>

          <div className="name-section">
            <span className="first-name">Rod</span>
            <div className="last-name">Korte</div>
            <i className="title-line extend-right"/>
          </div>

          <div className="about-me">
            <div className="about-me-photo" >
              <img src={aboutMe} />
            </div>

            <div className="about-me-text">
              <p>
                Practical Solutions and common sense is a cowboy&#x0027;s virtue. We make friends that last a lifetime
                and are proud of our families and heritage. Our team comes from generations of hard working
                farms, ranches and country raised families. Our team's purpose is to facilitate and bring value
                to your ranch for generations to come.
              </p>

              <p>
              Rod Korte was raised around horses and pursued rodeo, his children were raised in youth, HS
              and college rodeoing among the multitude of other activities they pursued. He has previously
              held board and president titles among youth rodeo organizations they were involved in. He has
              been on the Northern International Livestock expo (NILE) rodeo committee for a number of
              years. He also has extensive background in coaching and business building certified through
              John Maxwell Leadership programs. Rod holds certificates in Fast Trac business building, 3PL
              and a MBA certificate from Rocky Mountain college with micro economics and business
              communication classes. Rod is also a Honorably Discharged United States Marine.
              Praxis affiliated team members are industry leaders in the DRA and Financial Industry.
              Hunter & Molly Sullivan (CM Ranch), David VanBurkem (Rainbow Trout Ranch), Brent Kilmer
              (V Bar Ranch) Bret Robinson a life planning expert (Kennedy Financial Services).
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
